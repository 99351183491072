import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ButtonConfig,
  ButtonConfigBuilder,
  PrimeButtonComponent
} from '../../../../base-components';

@Component({
  standalone: true,
  selector: 'prime-simple-yes-no-footer',
  imports: [PrimeButtonComponent, NgIf, NgClass, AsyncPipe],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './simple-yes-no-footer.component.html'
})
export class SimpleYesNoFooterComponent implements OnInit {
  confirmButtonDisabled$: Observable<boolean>;
  @Input() cancelButtonLabel: string;
  @Input() confirmButtonLabel: string;
  @Input() closeOnCancel: boolean;
  @Input() cancelButtonConfig?: ButtonConfig;
  @Input() confirmButtonConfig?: ButtonConfig;
  @Input() justifyPosition: 'start' | 'end' | 'between';
  @Output() onCancel: EventEmitter<void>;
  @Output() onConfirm: EventEmitter<void>;
  @Input() set confirmButtonDisabledStreamManager(stream$: Observable<boolean> | null) {
    if (stream$) {
      this.confirmButtonDisabled$ = stream$;
    }
  }

  constructor(@Optional() private readonly dialogRef?: DynamicDialogRef) {
    this.closeOnCancel = true;
    this.justifyPosition = 'between';
    this.cancelButtonLabel = 'No';
    this.confirmButtonLabel = 'Yes';
    this.onCancel = new EventEmitter<void>();
    this.onConfirm = new EventEmitter<void>();
    this.confirmButtonDisabled$ = new BehaviorSubject<boolean>(false).asObservable();
  }

  ngOnInit(): void {
    if (!this.cancelButtonConfig) {
      this.cancelButtonConfig = new ButtonConfigBuilder(this.cancelButtonLabel).buttonConfig;
    }
    if (!this.confirmButtonConfig) {
      this.confirmButtonConfig = new ButtonConfigBuilder(this.confirmButtonLabel).buttonConfig;
    }
  }

  noClickHandler(): void {
    this.closeOnCancel && this.dialogRef?.close();
    this.onCancel.emit();
  }

  yesClickHandler(): void {
    this.onConfirm.emit();
  }
}
