<div
  [ngClass]="{
    'gap-2': justifyPosition === 'start' || justifyPosition === 'end'
  }"
  class="pb-4 px-4 pt-3 flex align-items-center justify-content-{{ justifyPosition }}"
>
  <prime-button
    (onClick)="noClickHandler()"
    *ngIf="cancelButtonConfig"
    [buttonConfig]="cancelButtonConfig"
    severity="secondary"
    [text]="true"
  ></prime-button>
  <prime-button
    (onClick)="yesClickHandler()"
    *ngIf="confirmButtonConfig"
    [buttonConfig]="confirmButtonConfig"
    [disabled]="confirmButtonDisabled$ | async"
  ></prime-button>
</div>
