<ng-container *ngIf="customHeader; else simpleHeader">
  <ng-container *ngTemplateOutlet="customHeader"></ng-container>
</ng-container>
<ng-template #simpleHeader>
  <div class="w-full flex align-items-center justify-content-between pt-4 px-4">
    <div class="text-2xl line-height-2rem font-medium text-color">{{ label ?? '' }}</div>
    <div
      (click)="closeIconHandler()"
      *ngIf="showClose"
      class="flex align-items-center justify-content-center w-2rem h-2rem cursor-pointer text-bluegray-900 hover:bg-bluegray-100 border-round-lg transition-all transition-duration-300"
    >
      <i
        [inlineSVG]="'sprites.svg#thin-close'"
        class="flex w-1rem h-1rem"
      ></i>
    </div>
  </div>
</ng-template>
