import { NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  standalone: true,
  selector: 'prime-simple-yes-no-header',
  imports: [NgIf, NgTemplateOutlet, InlineSVGModule, NgStyle],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './simple-yes-no-header.component.html'
})
export class SimpleYesNoHeaderComponent {
  @Input() label?: string;
  @Input() showClose: boolean;
  @ContentChild('customHeader', { static: false }) customHeader: TemplateRef<any> | null;

  @Output() onClose: EventEmitter<void>;

  constructor(@Optional() private readonly dialogRef?: DynamicDialogRef) {
    this.showClose = true;
    this.customHeader = null;
    this.onClose = new EventEmitter<void>();
  }

  closeIconHandler(): void {
    this.dialogRef?.close();
    this.onClose.emit();
  }
}
